.GeneralSearchPage {
  .item {
    border: 4px solid var(--text5);
    border-radius: 12px;
    margin-bottom: 12px;
    padding: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;

    i,
    svg {
      font-size: 24px;
      margin-right: 16px;
    }

    .cont {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 18px;
        font-weight: 600;
      }
      .subTitle {
        font-size: 16px;
        font-weight: 400;

        i,
        svg {
          font-size: 16px;
          padding: 0 6px;
        }
      }
    }
  }
}
