.RezeptPage {
  .top {
    margin-top: 132px;
  }

  .MediaCarousel {
    margin-bottom: 32px;
  }

  .icons {
    .icon {
      background: var(--bgSub);
      padding: 4px 12px;
      border-radius: 12px;
      margin-right: 4px;

      i,
      svg {
        margin-right: 4px;
      }

      span {
        font-size: 13px;
        font-weight: 800;
        ::selection {
          background: inherit !important;
        }
      }
    }
  }

  .images {
    display: flex;
    flex-direction: row;

    > div {
      //   width: 33%;
      padding-right: 8px;
      img {
        border-radius: 12px;
        width: 100%;
      }
    }
  }
}
