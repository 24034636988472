.HomePage {
  .MainContent {
    // padding: 24px;
    // padding-top: 0;
    // background: var(--bgSub);
    // box-shadow: 0 0 32px 8px var(--bgSub);
    // margin-top: 32px;

    border-top-right-radius: 32px;
    border-top-left-radius: 32px;
  }

  h1,
  .h1Height {
    margin-bottom: 24px;
  }
}
