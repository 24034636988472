.SearchInput {
  .input-group {
    background: var(--bgSub);

    margin: 0 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    input {
      flex: 1;
      width: 100%;
      overflow: hidden;
      color: var(--text1);

      border: 1px solid var(--text2);

      &::placeholder {
        color: var(--text2);
      }
    }

    button {
      i,
      svg {
        color: var(--text1);
        padding: 0 4px;
      }
    }
  }

  &.isOpen {
    input {
      max-width: 220px;
      padding: 8px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
