.RezeptQrPageInner {
  .articleBox {
    .textContent {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      h1 {
        font-size: 19px;
        font-weight: 600;
      }
    }
    .img {
      width: 100%;
      min-height: 80px;
      height: 100%;
      background-size: cover;
      background-position: center;
      border-right: 2px solid var(--main);
    }
  }
}
