.JoinNewsletterBox {
  p {
    font-size: 16px;
  }

  input {
    flex: 1;
    background: var(--bgSub);
    padding-right: 0;
    padding-left: 12px;
    font-size: 14px;
    width: 100%;
    margin-bottom: 12px;
  }
  button,
  .button {
    width: 100%;

    margin: 0;
    background: var(--sub);
    font-size: 14px;
  }
}
