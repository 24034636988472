.AuthorBox {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;

  .ProfileImage {
    width: 40px;
    height: 40px;
    border-radius: 40%;
    box-shadow: 0 0 1px 0.5px var(--text4);
    margin-right: 8px;
    background-size: cover;
  }

  span.name {
    display: flex;
    font-weight: 700;
    font-size: 12px;
  }

  span.date {
    display: flex;
    font-size: 12px;
    color: var(--sub);
    font-weight: 600;
  }
}
