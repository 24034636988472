.CounterInput {
  max-width: 100%;

  background: var(--bg);
  border-radius: 12px;
  overflow: hidden;
  display: inline;

  margin-right: 8px;

  * {
    display: inline;

    background: var(--bg);
  }

  input {
    text-align: center;
    margin: 0;
    padding: 0;
    border: none;
    // max-width: 32px;
    font-size: 14px;
    height: 100%;
    background: var(--bg);
  }

  button {
    margin: 0;
    padding: 0;
    color: var(--text1);
    padding: 0 12px;
    border-radius: 0;
    font-size: 10px;
    height: 100%;

    &.minus {
      border-radius: 12px 0 0 12px;
    }
    &.plus {
      border-radius: 0 12px 12px 0;
    }
  }
}
