.NewsItem {
  max-width: 100%;
  padding: 4px;

  article {
    height: 100%;
    .BorderRadiusBox {
      height: 100%;

      .inner {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          border-radius: 12px;
        }

        h3 {
          font-size: 18px;
          // font-size: ;
        }
        span.readingTime {
          font-size: 14px;
          color: var(--text3);
          i,
          svg {
            margin: 0 4px;
          }
        }

        transition: 0.2s;

        &:hover {
          transition: 0.2s;
          transform: translateY(-4px);
          opacity: 0.9;
        }

        .excerpt {
          p,
          * {
            font-size: 14px;
          }
        }
      }
    }
  }
}
