.InstagramQrCode {
  //   background: var(--main);

  .content {
    display: flex;
    flex-direction: column;
    // margin-top: -80px;

    h2 {
    }
  }

  .StaticImage.bg {
    position: relative;

    .qrCode {
      margin-left: auto;
      border-radius: 8px;
      overflow: hidden;
      width: 100px;

      right: 12px;
      bottom: 12px;
      position: absolute;

      opacity: 0.7;
      transition: 0.3s;
    }
    &:hover {
      .qrCode {
        width: 200px !important;
        opacity: 1 !important;
        transition: 0.3s;
      }
    }
  }
}
