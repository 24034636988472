.Search {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  backdrop-filter: blur(12px);
  background: rgba(0, 0, 0, 0.2);
  z-index: 15;
  > .inner {
    margin-top: 80px;
    padding: 16px 24px;
    background: var(--bg);

    overflow-y: scroll !important;
    height: calc(100% - 80px);

    .header {
      .Close {
        i,
        svg {
          font-size: 40px;
          color: var(--red);
        }
      }

      .SearchInfoText {
        input {
          padding: 12px 4px;
          background: transparent !important;
          border-radius: 4px;
        }
      }
      .SearchInfoSubText {
        font-size: 16px;
        color: var(--text3);
        font-weight: 600;
      }
    }

    .TypeSelector {
      display: flex;
      .Type {
        margin: 4px;
        padding: 4px 12px;
        font-weight: 600;
        border-radius: 12px;
        background: var(--bgSub);

        cursor: pointer;

        &.isActive {
          background: var(--sub);
          color: var(--sub_);
        }
      }
    }
  }

  transition: 0.4s;
  transform: translateX(0%) translateY(-100%);
  border-radius: 100%;
  opacity: 0.5;

  &.isOpen {
    opacity: 1;
    transition: 0.2s;
    border-radius: 0;
    transform: none;
    backdrop-filter: blur(12px);
    background: rgba(0, 0, 0, 0.2);
  }
}
