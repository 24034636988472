.ShareButtons {
  &.showBorder {
    border: 0;
    border-top-width: 8px;
    border-bottom-width: 8px;
    border-color: var(--text5);
    border-style: solid;
    margin: 32px 0;
  }
  padding: 32px 0;

  text-align: center;

  span.heading {
    color: var(--text2);
    display: block;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  .links {
    a {
      padding: 8px;
      cursor: pointer;

      svg,
      i {
        transition: 0.4s;
        color: var(--text2);
        font-size: 32px;
      }
      &:hover {
        svg,
        i {
          transition: 0.4s;
          color: var(--sub);
        }
      }
    }
  }
}
