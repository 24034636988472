.BuyPrintVersion {
  .content {
    position: relative;

    .inner2 {
      opacity: 0;
      background: linear-gradient(transparent 10%, #3a4f509d 90%, #28393a);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      transition: 0.8s;
    }
    .inner {
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      padding: 16px;
      background: linear-gradient(transparent 30%, #3a4f509d 85%, #28393a);
      display: flex;
      align-items: flex-end;

      cursor: pointer;
      opacity: 0.95;
      transition: 0.8s;
      h2 {
        color: #fff;
        font-weight: 700;
        margin-bottom: 0;
        transition: 0.8s;
        font-size: 24px;
        text-shadow: 0 0 12px #000a;
      }

      .icon {
        svg {
          font-size: 28px;
          color: var(--white);
          transition: 0.8s;
          margin-right: 4px;
        }
      }
    }
    .StaticImage {
      .asBackground {
        background-position: 0 center;
        background-size: auto 105%;
        transition: 0.8s;
      }
    }

    &:hover {
      .inner2 {
        transition: 0.4s;
        opacity: 1;
      }
      .inner {
        transition: 0.4s;
        opacity: 1;

        h2 {
          transform: scale(1.05) translateX(7px) translateY(-3px);
          transition: 0.4s;
        }
        .icon {
          svg {
            transform: scale(1.15) translateX(3px);
            transition: 0.4s;
            color: var(--white);
          }
        }
      }
      .StaticImage {
        .asBackground {
          transition: 0.4s;
          background-size: auto 100%;
        }
      }
    }
  }
}
