.HeaderMenuPush {
  height: calc(111px + 4px);
}
.HeaderMenu {
  position: fixed;
  z-index: 100;
  width: 100%;

  svg,
  i {
    color: var(--sub);
  }

  .blur {
    // height: 4px;
    backdrop-filter: blur(128px);
  }
  .sub {
    height: 4px;
    background: var(--sub);
  }

  .inner {
    // margin: 16px 24px;
    margin-top: 0;
    z-index: 200;
    min-height: 80px;
    background: var(--bg);

    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    // box-shadow: 0 0 32px var(--subBlur);

    .logo img {
      height: 42px;
      // width: 120px;
      margin-bottom: 12px;
      // max-width: 60vw;
    }

    .SearchInput {
      overflow: hidden;
      border-radius: 8px;
      margin-left: 8px;

      width: 300px;
      max-width: 100%;
      > * {
        border-radius: 0px;

        background-color: var(--bgSub);
      }
      input {
        font-size: 12px;
        padding: 4px 8px;
      }
      i,
      svg {
        padding: 0;
        font-size: 18px;
        color: var(--sub);
      }
    }

    .search {
      // display: flex;
      // flex-direction: row;
      // justify-content: center;
      // align-items: center;
      // flex: 1;
      // width: 320px;
    }

    .Links {
      a {
        // margin: 8px 6px;
        font-weight: 700;
        font-size: 18px;
        color: var(--main);
        background: transparent;

        padding: 0;
        margin-right: 12px;
        transition: 0.2s;

        .activePill {
          height: 6px;
          border-radius: 999px;
          width: 32px;
          background: var(--sub);
          opacity: 0;
          transform: translateY(100%);
          transition: 0s;

          &.true {
            transform: translateX(0);
            opacity: 0.9;
            transition: 0.4s;
          }
        }

        &.true {
          color: var(--sub);
          transition: 0.4s;
        }

        &.SMALL {
          font-size: 11px;

          color: var(--text2);
          padding: 6px 8px;
          margin: 0 0;
          i,
          svg {
            padding-right: 4px !important;
            color: var(--text2);
          }
        }

        display: inline-block;

        &:hover {
          transition: 0.4s;
          background: var(--bg);
          transform: scale(1.05);
          z-index: 100;
        }
      }
    }

    .CategorySelection > div {
      flex-direction: row-reverse !important;

      .CategoryButton {
        margin-right: 0;
        margin-left: 4px;
      }
    }
  }

  .MobileMenu {
    max-height: 0px;
    padding: 0 12px;
    overflow: hidden;
    transition: 0.3s;
    opacity: 0.5;
    // transform: rotate(1deg) scaleX(1.1);

    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;

    backdrop-filter: blur(32px);

    .LinksMobile {
      display: flex !important;
      flex-direction: column;
      border-radius: 12px;
      width: 100%;

      a {
        font-size: 22px;
        padding: 12px;
        background: var(--bg);
        color: var(--main);
        margin-bottom: 12px;
        border-radius: 12px;
        width: 100%;
        font-weight: 600;

        i,
        svg {
          padding-right: 8px;
          padding-left: 8px;
          color: var(--sub);
        }
      }
    }

    &.isVisible {
      opacity: 1;
      transform: scale(1);
      transition: 0.3s;
      max-height: 100vh;
      padding: 12px;
    }
  }
}
