.RezeptScore {
  .overview {
    background: var(--bgSub);
    border-radius: 16px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;

    h3,
    p {
      margin: 0;
      margin-right: 8px;
      color: var(--main);
    }

    i,
    svg {
      font-size: 44px;
      transition: 0.2s;
      width: 56px;
      color: #ddb214;
    }

    &:hover {
      i,
      svg {
        transition: 0.2s;
        transform: scale(1.2) rotate(3deg);
      }
    }
  }

  .text {
    padding: 16px;
  }
}
