.HeaderCook {
  .logo img {
    height: 20px;
  }

  .title {
    span.reformCook {
      display: flex;
      font-size: 12px;
      text-align: center;
    }
    span.receptTitle {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .leave {
    color: var(--red);
    font-weight: 300;
    text-transform: uppercase;
    padding: 8px;
  }

  hr {
    color: var(--text4);
  }

  .options {
    button {
      border-radius: 40%;
      width: 44px;
      height: 44px;
      padding: 0;

      background-color: var(--blue);
      transition: 0.5s;
      margin-left: 0;

      svg,
      i {
        text-align: center;
        color: var(--blue_);
      }

      &.false {
        background-color: var(--red);
        svg,
        i {
          text-align: center;
          color: var(--red_);
        }
      }
    }
  }
}
