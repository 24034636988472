.MagazinePage {
  min-height: 100vh;

  .featured .NewsItemTile {
  }

  .NewsItem {
    .BorderRadiusBox {
      // background-color: var(--bg);
    }
  }

  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 20px;
  }

  .sidebar {
    p {
      font-size: 14px;
    }
  }
}
