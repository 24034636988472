.KontaktPage {
  h2 {
    font-size: 32px;
  }

  button {
    margin-left: 0;
  }

  p.welcome {
    font-size: 24px;
  }
}
