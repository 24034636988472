.PageRedirectOr404,
.Page404 {
  padding-top: 128px;
  text-align: center;

  .container {
    text-align: center;

    i,
    svg {
      font-size: 98px;
      margin-bottom: 12px;
      margin-bottom: 24px;
    }
  }
}

.RedirectPage {
  padding-top: 52px;
  min-height: 80vh;
  .container {
    max-width: 700px;
  }

  p {
    white-space: pre-line;
  }
}
