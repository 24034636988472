.App {
  min-height: 100vh;
  margin: 0;

  display: grid;
  grid-template-rows: auto 1fr auto;

  .pages > div {
    animation: fadeIn 0.15s 1;
  }
  @keyframes fadeIn {
    0% {
      transform: translateY(120px);
      opacity: 1;
    }
    100% {
    }
  }

  footer {
    margin-top: auto;
  }

  .Page {
    min-height: calc(100vh - 80px);
  }
}
