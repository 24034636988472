body.dark-theme img {
  filter: brightness(0.8) contrast(1.2);
}

html {
  // --bgMid: #f1f0f5;
  // --bgUp: #fff;
  // --bgDown: #ebebeb;

  --fade: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.9) 10%,
    rgba(0, 212, 255, 0) 100%
  );

  --bg: #fff;
  --bgSub: #f1f3f9;
  --shadow: #00000016;

  --black: #000;
  --white: #fff;
  --blue: #4a67e4;
  --blue_: #fff;
  --red: #d93636;
  --blue_: #fff;
  --green: #08801a;
  --green_: #fff;
  --yellow: #dbbf08;
  --yellow_: #000;

  --bgColored: #424eed;
  --bgColored_: #fff;

  --text1: #1a1a18;
  --text2: #444;
  --text3: #777;
  --text4: #aaa;
  --text5: #eee;

  --main: #1a1a18;
  --main_: #eee;

  --sub: #e30075; //TK
  // --sub: #e60c80;
  // --sub: #ab045d; DUNKLER
  --subLight: #f9deec;
  --subLighter: #ec1e85;
  --subDarker: #bf0467;
  --subBlur: #cc01676f;
  --sub_: #fff;
  --sub_blue: #bad1fc;

  --bgContrast: #19142d;
  --bgContrast_: #f5f3fc;

  --fa-primary-color: var(--main);
  --fa-secondary-color: var(--sub);
  --fa-secondary-opacity: 0.5;
}

@media (prefers-color-scheme: dark) {
  img {
    filter: brightness(0.8) contrast(1.2);
  }

  html {
    --fade: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.9) 10%,
      rgba(0, 0, 0, 0) 100%
    );

    --bg: #111;
    --bgSub: #000;

    --black: #000;
    --white: #fff;
    --blue: #4a67e4;
    --blue_: #fff;
    --red: #d93636;
    --blue_: #fff;

    --text1: #fff;
    --text2: #ddd;
    --text3: #aaa;
    --text4: #777;
    --text5: #222;

    --main: #eee;
    --main_: rgb(0, 0, 0);

    --sub: #e30075; //TK
    // --sub: #e60c80;
    // --sub: #ab045d; DUNKLER
    --subLight: #f9deec;
    --subLighter: #ec1e85;
    --subDarker: #bf0467;
    --subBlur: #cc01676f;
    --sub_: #fff;
    --sub_blue: #bad1fc;

    --bgContrast: #19142d;
    --bgContrast_: #f5f3fc;

    --fa-primary-color: var(--main);
    --fa-secondary-color: var(--sub);
    --fa-secondary-opacity: 0.5;
  }
}
