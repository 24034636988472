.Breadcrumps {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 16px;

  .paths {
    display: flex;
    flex-direction: row;
    align-items: center;

    .pathItem {
      display: flex;
      flex-direction: row;
      align-items: center;

      word-break: keep-all;
      max-lines: 1;
      height: 32px;
      overflow: hidden;

      font-size: 13px;

      svg,
      i {
        margin-right: 8px;
        color: var(--text3);
      }
      span {
        font-weight: 500;
        margin-right: 6px;
        color: var(--text3);
      }

      &:hover {
        color: initial;
        span {
          text-decoration: underline var(--text3);
        }
      }

      &:last-of-type {
        font-size: 12px;

        // background: var(--subLight);
        border-radius: 8px;
        span {
          color: var(--text4);
          margin-right: 0px;
        }
        svg,
        i {
          display: none;
        }
        &:hover {
          span {
            text-decoration: none !important;
          }
        }
      }
    }
  }
}
