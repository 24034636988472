footer {
  padding: 32px;
  padding-top: 80px;
  padding-bottom: 40px;
  background: var(--bgSub);

  .row > div {
    margin-bottom: 24px;
  }

  .items {
    a {
      font-size: 17px;
      color: var(--text1);
      display: block;
    }

    i {
      min-width: 25px;
    }

    a .fab {
      padding-right: 6px;
    }
  }

  hr {
    border-color: var(--text5);
    margin-bottom: 8px;
  }

  a {
    &:hover {
      transform: translateX(2px);
      transition: 0.2s;
      color: var(--sub) !important;
    }

    &:focus {
      transform: translateX(16px) scale(1.05);
      transition: 0.2s;
    }

    transition: 0.6s;
  }

  h3 {
    font-size: 22px;
    color: var(--sub);
  }

  .copyright {
    color: var(--grey);
    font-weight: 300;
    font-size: 12px;
  }

  .lowItems a {
    color: var(--grey);
    font-weight: 300;
    font-size: 12px;
    margin-right: 12px;
  }
}

.footer .copyrightArea {
  padding-top: 32px;
}
