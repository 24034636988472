.RezeptNutritionalValues {
  a {
    color: var(--blue);
  }

  .Label {
    background: var(--bgSub);
    margin: 2px;
    padding: 2px 8px;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .dot {
      background-color: red;
      height: 20px;
      width: 20px;
      border-radius: 8px;
      margin-right: 4px;
    }
  }

  .inner {
    overflow: hidden;
    // border: 1px solid black;

    canvas {
      margin-top: -32px;
    }
  }
}
