.BookPage {
  h1 {
    color: var(--text1);
    font-size: 28px;
    margin-bottom: 0;
    font-weight: 500;
  }

  .price {
    color: var(--sub);
    font-size: 52px;
    font-weight: 600;
  }

  .description {
    white-space: pre-line !important;
  }

  .buttons {
    margin-left: -4px;
    button {
      //   text-align: start;
      padding: 12px 8px;
      width: 60%;
    }
  }
}
