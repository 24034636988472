.CookButton {
  display: flex;
  align-items: center;

  cursor: no-drop !important;

  small {
    font-size: 10px;
  }

  &:hover {
    transform: none !important;
  }
}
