.BorderRadiusBox {
  box-shadow: 0 0 0px 0.5px var(--shadow);
  border-radius: 16px;
  margin-bottom: 16px;
  position: relative;
  overflow-x: hidden;
  background: var(--bgSub);

  .bg {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;

    svg {
      width: 100%;
    }
  }

  .inner {
    &.innerBorderRadius {
      border-radius: 12px;
      overflow: hidden;
    }
  }
}
