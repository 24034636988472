.NetworkErrorPage {
  padding-top: 128px;
  text-align: center;

  i,
  svg {
    font-size: 120px;
    margin-bottom: 12px;
    color: var(--red);
  }
  h1 {
    color: var(--red);
  }
}
