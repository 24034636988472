.AuthorPage {
  p {
    white-space: pre-line;
  }

  .profileImage {
    width: 320px;
    border-radius: 33%;
  }

  h2 {
    font-size: 32px;
  }
}
