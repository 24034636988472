.CategoryPage {
  h1 {
    font-size: 52px;
  }

  .titleIcon {
    font-size: 92px;
    color: var(--text2);
  }

  button {
    margin-left: 0;
  }
}
