.BookBox {
  display: flex;
  align-items: center;

  img {
    margin-right: 16px;
    max-width: 80px;
    border-radius: 4px;
    border: 0.5px solid var(--text1);
  }

  span {
    display: block;

    &.price {
    }
    &.title {
      color: var(--main);
      font-weight: 700;
      font-size: 20px;
    }
    &.authorText {
      color: var(--sub);
    }
  }
}
