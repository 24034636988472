.NewsArticlePage {
  :target:before {
    content: "";
    display: block;
    height: 100px;
    margin: -100px 0 0;
  }

  span.isAdText {
    color: var(--text3);
  }
  // Inhaltsübersicht
  .ContentOverview {
    h2 {
      font-size: 32px;
    }
    .item {
      display: block;
      font-weight: 600;
      font-size: 22px;
      color: var(--text1);
      line-height: 100%;
      margin-bottom: 12px;

      transition: 0.2s;
      cursor: pointer;

      &:hover {
        transition: 0.2s;
        color: var(--text2);
      }

      &:focus {
        transform: scale(1.03);
      }
    }
  }

  article {
    .AdBanner {
      margin-bottom: 32px;
    }
    p {
      padding-bottom: 32px;
    }

    h2 {
      font-size: 38px;
    }

    h3 {
      font-size: 30px;
    }

    .tt {
      position: relative;
      display: inline-block;

      cursor: alias;
      color: var(--main);
      font-weight: 600;

      i,
      svg {
        margin-left: 0px;
      }

      /* Tooltip text */
      .ttt {
        visibility: hidden;
        max-width: 440px;
        min-width: 240px;
        background-color: var(--bg);
        color: var(--text1);
        text-align: left;
        border-radius: 6px;

        box-shadow: 0 0 2px 0 var(--text4);

        padding: 4px 8px;
        font-size: 14px;

        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;

        font-weight: 300;

        span.ttTitle {
          font-weight: 500;
        }
      }

      /* Show the tooltip text when you mouse over the tooltip container */
      &:hover,
      &:focus {
        .ttt {
          margin-top: 24px;
          visibility: visible;
        }
      }
    }
  }
}
