.MagazinBox {
  display: flex;
  align-items: center;

  img {
    margin-right: 16px;
    max-width: 80px;
    border-radius: 4px;
    border: 0.5px solid var(--text1);
  }

  .countNumber {
    display: block;
    color: var(--main);
    font-weight: 800;
    font-size: 22px;
  }

  .numberString {
    display: block;
    color: var(--sub);
    font-weight: 600;
    font-size: 16px;
  }
}
