.MagazineItemPage {
  .box-header {
    background-color: var(--bgSub);

    .cover {
      border: 2px solid var(--text5);
      border-radius: 8px;
    }

    button {
      width: 100%;
    }

    h1 {
      display: inline;
      font-size: 30px;
      .black {
        color: var(--main);
        small {
          color: var(--main);
        }
      }
    }
    h2 {
      font-size: 32px;
    }

    p {
      font-size: 14px;
      white-space: break-spaces;
    }

    span.detail {
      display: block;
      font-size: 14px;

      &.price {
        font-size: 18px;
        color: var(--text1);
        font-weight: 700;
      }
    }
  }
}
