.debug {
  display: none;
}

* {
  outline: none !important;
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-block {
    display: block !important;
  }
}

body {
  background: var(--bg);
  color: var(--text2);
}
a {
  color: inherit;
}

.container {
  max-width: 1200px;
}

input {
  background: var(--bg);
  padding: 12px 24px;
  border-radius: 12px;
  font-weight: 500;
  border: none;
  color: var(--sub);

  &:focus {
    background: var(--bgSub);
  }
}

.input-group {
  background: var(--bg);
  border-radius: 12px;
  border: none !important;

  input,
  button,
  .button {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    border-radius: 12px;

    border: none !important;
    background: transparent;
  }
}

button,
.button {
  border: none;
  background: var(--main);
  color: var(--main_);
  margin: 4px 8px;
  padding: 8px 16px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 20px;

  transition: 0.2s;
  opacity: 0.9;

  i,
  svg {
    color: var(--main_);
    font-size: 20px;
    padding: 0 2px;
  }
  span {
    padding: 0 2px;
  }

  &.main {
    background: var(--sub);
    color: var(--sub_);
  }

  &.sub {
    background: var(--text3);
    color: var(--sub_);
  }

  &.clear {
    background: transparent;
    color: inherit;
    padding: 0;
    margin: 0;
  }

  &:hover {
    transition: 0.2s;
    opacity: 1;
  }
}
@media (max-width: 575.98px) {
  button {
    margin: 2px 4px;
    padding: 4px 10px;
    border-radius: 12px;
    font-weight: 600;
    font-size: 17px;
  }
}

input[type="checkbox"] {
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;
  border-radius: 50% !important;
  overflow: hidden;
  border: none;

  /* On mouse-over, add a grey background color */
  & ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  &:checked ~ .checkmark {
    background-color: var(--main);
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

i .round,
svg.round {
  font-size: 16px;
  background: var(--sub);
  color: var(--sub_);
  padding: 8px;
  border-radius: 40%;
  margin-bottom: 4px;
}

.green {
  color: var(--green);
}
