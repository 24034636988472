.ShoppingList {
  background: var(--bgSub);
  border-radius: 13px;
  padding: 8px 12px;

  align-items: flex-end;

  // animation: fadeIn infinite 2s;

  .IngredientItem {
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;

    span.amount {
      display: block;
      animation: fadeInAmount linear 0.5s;
      padding-right: 4px;
      font-weight: 600;
    }
  }
}

@keyframes fadeInAmount {
  0% {
    opacity: 0;
    transform: scale(1.3) translateX(-30px);
  }
  100% {
  }
}

@keyframes fadeInTitle {
  0% {
    opacity: 0;
    transform: scale(1.1) translateX(30px);
  }
  100% {
  }
}
