.AdBanner {
  // min-height: 210px !important;

  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  margin-bottom: 16px;

  border-radius: 2px;
  overflow: hidden;

  .adsbygoogle {
    // // background-color: #111;
    min-height: 200px !important;
    width: 100%;
    min-width: 250px !important;
  }

  // * {
  //   min-height: 60px !important;
  // }
  span {
    min-height: none !important;
  }
  small {
    // position: relative;
    // top: 0;
    // left: 0;
    // bottom: 0;
    // right: 0;
    opacity: 0.2;
  }

  // margin-bottom: 12px;
}
