.TitleImageBox {
  width: 100vw;
  margin-bottom: 24px;

  background-color: var(--main);
  background-position: center;
  background-size: cover;
  width: 100vw;

  .inner {
    width: 100vw;
    min-height: 700px;
    display: flex;
    align-items: flex-end;

    background: var(--fade);

    .CategoryText {
      font-size: 22px;
      color: var(--text1);
      font-weight: 800;
    }
  }
}
