.ServerStatusPage {
  h3 {
    small {
      font-weight: 400;
      color: var(--text3);
    }
  }
  .dot {
    width: 32px;
    height: 32px;
    background: var(--green);
    border-radius: 12px;
    margin: 8px;
    &.red {
      background: var(--red);
    }
    &.yellow {
      background: var(--yellow);
    }
  }
}
