.AblaufplanPage {
  h2 {
    font-size: 32px;
  }

  .AblaufplanEntry {
  }

  .bar {
    height: 128px;
    width: 24px;
    border-radius: 10px;
    background: "#000";
    margin-right: 12px;
  }

  .legende {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .LegendeItem {
      background: var(--bgSub);
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 4px;
      padding: 4px;
      border-radius: 12px;
      .dot {
        width: 24px;
        height: 24px;
        border-radius: 10px;
        margin: 4px;
      }
      span {
        margin: 0 4px;
      }
    }
  }
}
