/* poppins-100 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  src: url("https://cdn.diestadt.app/font/poppins-v20-latin-100.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("https://cdn.diestadt.app/font/poppins-v20-latin-100.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-100.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-100.woff")
      format("woff"),
    /* Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-100.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-100.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
/* poppins-200 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src: url("https://cdn.diestadt.app/font/poppins-v20-latin-200.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("https://cdn.diestadt.app/font/poppins-v20-latin-200.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-200.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-200.woff")
      format("woff"),
    /* Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-200.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-200.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
/* poppins-300 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: url("https://cdn.diestadt.app/font/poppins-v20-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("https://cdn.diestadt.app/font/poppins-v20-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-300.woff")
      format("woff"),
    /* Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-300.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
/* poppins-regular - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn.diestadt.app/font/poppins-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("https://cdn.diestadt.app/font/poppins-v20-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-regular.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
/* poppins-500 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("https://cdn.diestadt.app/font/poppins-v20-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("https://cdn.diestadt.app/font/poppins-v20-latin-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-500.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-500.woff")
      format("woff"),
    /* Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-500.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("https://cdn.diestadt.app/font/poppins-v20-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("https://cdn.diestadt.app/font/poppins-v20-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-600.woff")
      format("woff"),
    /* Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-600.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
/* poppins-700 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("https://cdn.diestadt.app/font/poppins-v20-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("https://cdn.diestadt.app/font/poppins-v20-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-700.woff")
      format("woff"),
    /* Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-700.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
/* poppins-800 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: url("https://cdn.diestadt.app/font/poppins-v20-latin-800.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("https://cdn.diestadt.app/font/poppins-v20-latin-800.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-800.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-800.woff")
      format("woff"),
    /* Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-800.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-800.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
/* poppins-900 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: url("https://cdn.diestadt.app/font/poppins-v20-latin-900.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("https://cdn.diestadt.app/font/poppins-v20-latin-900.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-900.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-900.woff")
      format("woff"),
    /* Modern Browsers */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("https://cdn.diestadt.app/font/poppins-v20-latin-900.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
* {
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 32px;
  font-weight: 800;
  color: var(--sub);
  margin-bottom: 4px;
  b {
    font-weight: 900;
    color: var(--text1);
  }
}
.subTitle {
  font-size: 24px;
  font-weight: 800;

  a {
    color: var(--blue);
    opacity: 0.9;
    transition: 0.2s;

    &:hover {
      transition: 0.2s;
      opacity: 1;
      text-decoration: underline;
    }
    &:focus {
      transition: 0.2s;
      text-decoration: none;
    }
  }
}
h2 {
  font-size: 26px;
  color: var(--sub);
  margin-bottom: 12px;
  font-weight: 800;

  b {
    color: var(--text1);
  }

  small {
    font-size: 20px;
    color: var(--text2);
  }

  span {
    color: var(--text1);
  }
}
h3 {
  font-size: 22px;
  font-weight: 800;
  color: var(--main);

  b {
    color: var(--sub);
  }
}

h4 {
  font-weight: 800;
  font-size: 20px;
}
p {
  font-size: 20px;
  font-weight: 300;
  color: var(--text2);
}
b,
b * {
  font-weight: 800 !important;
}
p {
  b,
  b * {
    font-weight: 600 !important;
  }
}

@media (max-width: 575.98px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 24px !important;
  }
  h3 {
    font-size: 20px !important;
  }
  .subTitle {
    font-size: 19px;
    font-weight: 800;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 22px;
  }
}

.red {
  color: var(--red);
  font-weight: 600;
}
