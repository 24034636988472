.RezeptCookPage {
  .container {
    // padding: 0;
  }
  h1 {
    font-size: 24px;
    margin-left: -28px;
  }
  h2 {
    // font-size: 24px;
    color: var(--text3);
  }

  .potionAmountSelection {
    .CounterInput {
      input {
        width: 150px;
        font-size: 64px;
        border: 0;
        font-weight: 900;
        color: var(--text1);
      }
      button {
        font-size: 64px;
      }
    }
  }

  .ShoppingList {
    background: var(--bgSub);
    border-radius: 14px;
    padding: 16px;

    height: 40vw;

    .subHeading {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      color: var(--text3);

      .CounterInput {
        button {
          background: transparent;
          padding: 0;
          color: var(--text2);
        }
        input {
          background: transparent;
          border: none;
          width: 32px;
          text-align: center;
        }
      }
    }
    .heading {
      font-size: 20px;
      font-weight: 800;
      display: block;
    }

    hr {
      margin-top: 8px;
    }

    table {
      tr.ingredient {
        td {
          padding-right: 16px;

          // .amount {
          //   font-size: 16px;
          //   font-weight: 800;
          // }
          // .title {
          //   font-size: 16px;
          // }
        }
      }
    }
  }
}
