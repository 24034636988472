.AdBlockDetector {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2600000;
  background-color: var(--bg);
  overflow-y: scroll;

  .container {
    max-width: 1000px;
  }

  .top {
    margin-top: -4px;
    background-color: var(--main);
    .container {
      min-height: 320px;
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: space-evenly;
    }
    h1 {
      font-size: 24px;
      color: var(--sub_);
    }
    h2 {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .box {
    background: var(--bg);
    border: 4px solid var(--bgSub);
    padding: 24px;
    border-radius: 24px;
    margin-top: -32px;
    margin-bottom: 42px;

    h3 {
      font-size: 20px;
      font-weight: 800;
    }

    p,
    li {
      font-size: 18px;
    }
    button {
      background-color: var(--sub);
      width: 100%;
      height: 64px;
    }

    input {
      background: var(--bgSub);
      padding: 16px;
      height: 44px;
      text-align: center;
      text-transform: uppercase;
    }
  }
}
