.WelcomeTextBox {
  .StaticImage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    img {
      max-width: 500px;
    }

    img {
      .tspan {
      }
    }
  }
}
