.CategorySelection {
  h2 {
    margin-bottom: 18px;
  }

  .CategoryButton {
    margin-right: 4px;
    margin-bottom: 4px;
    border-radius: 6px;

    cursor: pointer;
    padding: 3px 6px;
    height: 100%;
    h3 {
      font-size: 13px;
      margin-bottom: 0;
      font-weight: 600;

      i,
      svg {
        color: #fff;
        font-size: 13px;
        margin-right: 4px;
      }
    }

    &.remove {
      i,
      svg {
        padding: 0 !important;
      }
    }
  }
}
