.TextPage {
  padding-top: 0;
  padding-bottom: 64px;
  margin-top: -120px;

  h1 {
    margin-bottom: 20px;
  }

  .header {
    .headerContent {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: absolute;
      z-index: 10;
      top: 100px;
      left: 0;
      right: 0;

      .inner {
        background: rgba(255, 255, 255, 0.66);
        max-width: 500px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 32px;
        border-radius: 32px;
      }
    }
    .headerImage {
      width: 100vw;

      height: 100%;
      // min-height: 400px;
      background-size: cover;
      background-position: 0 20%;

      background-color: var(--sub);
      margin-bottom: 32px;
      display: flex;
      border-radius: 24px;

      .inner {
        background: var(--sub_b);
        height: 100%;
        width: 100vw;
        flex: 1;

        .container {
          min-height: 450px;
          height: 100%;
          width: 100vw;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          h1 {
            color: var(--sub_);
          }
          .subTitle {
            font-weight: 400;
            font-size: 28px;
            color: var(--main_);
          }
        }
      }
    }
  }

  .SuperBanner {
    position: absolute;
    right: 8px;
    top: 600px;
    z-index: 10;

    .AdBanner {
      margin-bottom: 8px;
    }
  }
}
