.RezeptPageItem {
  * {
    transition: 0.5s;
  }

  cursor: pointer;

  .imageBox {
    border-radius: 14px;
    background-size: 105% auto;
    background-position: center;
  }

  .title {
    font-size: 18px;
    font-weight: 800;
  }

  .actionButtons {
    display: flex;
    margin: 0;

    > * {
      flex: 1;
      button {
        font-size: 16px;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  &:hover {
    * {
      transition: 0.2s;
    }
    .imageBox {
      background-size: 100% auto;
    }
  }
}
