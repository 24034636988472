.NewsItemTile {
  .inner {
    .StaticImage {
      .content {
        padding: 24px 0;

        display: flex;
        flex-direction: row;
        align-items: center;

        background-color: #30011a6d;

        span.label {
          background-color: var(--sub);
          color: var(--sub_);
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
          padding: 2px 8px;
          border-radius: 4px;
          margin-bottom: 8px;
          box-shadow: 0 0 16px 1px var(--sub);
        }
        span.title {
          color: #fff;
          display: block;
          font-size: 26px;
          font-weight: 800;
          text-shadow: 0 0 4px #000;
        }
        span.desc {
          color: #fff;
          display: block;
          font-size: 20px;
          font-weight: 400;
          text-shadow: 0 0 4px #000;
        }
      }
    }
  }
}
