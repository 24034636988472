.RezeptCookPageStep {
  span.emoji {
    font-size: 128px;
  }

  button {
    font-size: 24px;
    padding: 12px;
    background: var(--blue);
    color: var(--blue_);
    width: 100%;
    max-width: 600px;

    position: absolute;

    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);

    &:hover {
      transform: translateX(-50%);
    }
  }
}
