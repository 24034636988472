.ColoredBox {
  background: var(--bgContrast);
  padding: 16px;

  .StaticImage {
    // transform: scaleX(-1);
  }

  * {
    color: var(--bgContrast_);
  }
}
