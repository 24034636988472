.ProfileImage {
  width: 64px;
  height: 64px;
  background: var(--text5);
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  i {
  }

  img,
  .StrapiImage {
    min-height: 100%;
    min-width: 100%;
  }

  span {
    font-size: 28px;
    font-weight: 600;
    opacity: 1;
    color: var(--sub);
    text-transform: capitalize;
  }
}
