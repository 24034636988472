.NewsList {
  //   > div {
  //     padding: 8px;
  //     width: 100%;
  //   }

  // overflow: hidden;
  // overflow-x: scroll;

  overflow: visible;

  .NewsItem {
  }
}
