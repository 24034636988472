.BasisProgrammBox {
  text-align: center;
  padding: 20px;
  h2 {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  p {
    font-size: 18px;
  }
  img {
    margin-bottom: 12px;
    border-radius: 12px;
  }
}
