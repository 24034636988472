.WelcomeCarousel {
  .StaticImage {
    .asBackground {
      height: 70vh;
      max-height: 100vh;
      width: 100%;
      background-position: 55% 0%;

      .inner {
        height: 100%;
        width: 100%;
        background-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.5) 1%,
          rgba(56, 4, 4, 0.4) 80%,
          transparent
        );

        display: flex;
        flex-direction: row;
        justify-content: center;

        padding-bottom: 32px;

        h1 {
          color: #fff;
          margin-bottom: 0;
        }

        .subTitle {
          color: #eee;
        }
      }
    }
  }
}
