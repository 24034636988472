.AuthorBoxBig {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: center;

  .ProfileImage {
    margin-right: 12px;
    height: 64px;
    width: 64px;
    border-radius: 26px;

    img {
      width: 64px;
      height: 64px;
      border-radius: 33%;
    }
  }

  div {
    span {
      display: block;
    }
    span.title {
      color: var(--text3);
      font-size: 14px;
      margin-bottom: -4px;
    }
    span.name {
      font-weight: 600;
      font-size: 18px;
      color: var(--main);
    }
  }

  i,
  svg {
    // font-size: 32px !important;
    color: var(--text4);
    transition: 0.2s;
  }

  transition: 0.2s;

  &:hover {
    transition: 0.2s;
    transform: scale(1.02) translateX(4px);
    i,
    svg {
      transition: 0.2s;
      transform: scale(1.1);
      color: var(--sub);
    }
  }

  &:focus {
    transition: 0.2s;
    transform: scale(1.04) translateX(8px);
  }
}
