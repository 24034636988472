.GhostLoader_TrendingBox {
  .trendingBoxItems {
    padding-top: 12px;
    .TrendingBoxItem {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      margin-bottom: 8px;
      span.number {
        width: 24px !important;
        max-width: 24px;
        font-weight: 800;
        color: var(--text4);
        font-size: 17px;
        margin-right: 12px;
      }
      img {
        max-width: 50px;
        border-radius: 12px;
        margin-right: 12px;
      }
      h3 {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}
