.RegisterToNewsletter {
  display: flex;
  flex-direction: column;

  input {
    background: var(--bgSub) !important;
  }

  button {
    margin: 0;
    width: 100%;
  }

  .fa-solid {
    font-size: 55px;
    color: var(--green);
  }
}
